import styles from 'legacy/common/components/form/Form.module.scss';

import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';

import { handleLogin } from 'legacy/auth/helpers';
import { useEmailLoginCommand } from 'legacy/auth/hooks';

import Button, { ButtonType } from 'legacy/common/components/button/Button';
import useParams from 'legacy/common/hooks/useParams';

const EmailLoginSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').required('Required'),
  password: Yup.string().required('Required')
});

const EmailLoginForm = () => {
  const params = useParams<{ email: string }>();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible);
  };

  const { mutate, isLoading, error } = useEmailLoginCommand();

  const passwordRef = useRef<HTMLInputElement>();
  useEffect(() => {
    // Focus the password field when email is prefilled
    if (params.email) {
      passwordRef.current?.focus();
    }
  }, [params.email]);

  return (
    <>
      <p className={styles.submitError}>{error?.message}</p>

      <Formik
        initialValues={{
          email: params.email ?? '',
          password: ''
        }}
        enableReinitialize
        onSubmit={(values) => {
          mutate(values, {
            onSuccess: (data) => {
              handleLogin(data);
            }
          });
        }}
        validationSchema={EmailLoginSchema}
      >
        {({ errors, touched, dirty }) => (
          <Form>
            <div className={styles.group}>
              <p className={classnames(styles.label, styles.small)}>Email</p>
              <Field
                id="email"
                name="email"
                type="text"
                placeholder="Enter your email"
                className={classnames(styles.input, {
                  [styles.errorField]: errors.email && touched.email
                })}
              />
              {errors.email && touched.email && (
                <div className={classnames(styles.error, styles.right)}>
                  {errors.email}
                </div>
              )}
              <p className={classnames(styles.label, styles.small)}>Password</p>
              <div className={styles.relative}>
                <Field
                  id="password"
                  name="password"
                  innerRef={passwordRef}
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder="Enter your password"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.password && touched.password
                  })}
                />
                {errors.password && touched.password && (
                  <div className={classnames(styles.error, styles.right)}>
                    {errors.password}
                  </div>
                )}
                <div
                  className={styles.inputIcon}
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <img src="/img/icons/eye.svg" />
                  ) : (
                    <img src="/img/icons/eye-slash.svg" />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.group}>
              <Button
                isSubmit
                disabled={!dirty}
                loading={isLoading}
                type={ButtonType.Primary}
              >
                Get Started
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailLoginForm;
