import styles from 'legacy/common/components/form/Form.module.scss';

import React, { useState } from 'react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';

import {
  useConfirmLoginVerificationSessionCommand,
  useCreateLoginVerificationSessionCommand,
  useUpdateLoginVerificationSessionCommand
} from 'legacy/auth/hooks';

import { getLoginMethod, handleLogin } from 'legacy/auth/helpers';

import { VerificationSessionDto } from 'legacy/auth/types';

import useParams from 'legacy/common/hooks/useParams';

import Button from 'legacy/common/components/button/Button';
import FormWrapper from 'legacy/auth/components/FormWrapper';
import LoginForm from 'legacy/auth/components/login/LoginForm';
import Wrapper from 'legacy/common/components/layout/BackgroundWrapper';
import LoginConfirmation from 'legacy/auth/components/login/LoginConfirmation';
import LoginWithPasswordForm from 'legacy/auth/components/login/LoginWithPasswordForm';
import OtherLoginMethods from '../components/OtherLoginMethods';

const handleLegacyEmailLogin = (email: string, from: string) => {
  Router.push(
    {
      pathname: '/login',
      query: {
        type: 'email',
        email,
        from
      }
    },
    '/login'
  );
};

const handleLegacyPhoneLogin = (phone: string, from: string) => {
  Router.push(
    {
      pathname: '/login',
      query: {
        type: 'phone',
        phone,
        from
      }
    },
    '/login'
  );
};

const SignUpButton = () => {
  const { query } = useRouter();

  return (
    <>
      <div className={styles.separator}>
        <span />
        <div>OR</div>
        <span />
      </div>
      <div className="mt-6">
        <Link
          href={{
            pathname: '/register',
            query
          }}
          legacyBehavior
        >
          <Button data-test="sign-up">Sign Up</Button>
        </Link>
      </div>
    </>
  );
};

const Login = () => {
  const params = useParams<{ from: string }>();

  const [phoneOrEmail, setPhoneOrEmail] = useState('');

  const [verificationSession, setVerificationSession] =
    useState<VerificationSessionDto>();
  const createLoginVerificationSessionCommand =
    useCreateLoginVerificationSessionCommand();
  const updateLoginVerificationSessionCommand =
    useUpdateLoginVerificationSessionCommand();
  const confirmLoginVerificationSessionCommand =
    useConfirmLoginVerificationSessionCommand();

  const handleInitialLogin = (values: {
    phoneOrEmail: string;
    password: string;
    callingCode: string;
    countryCode: string;
  }) => {
    setPhoneOrEmail(values.phoneOrEmail);

    const loginMethod = getLoginMethod(values.phoneOrEmail);

    if (loginMethod === 'email') {
      createLoginVerificationSessionCommand.mutate(
        {
          email: values.phoneOrEmail
        },
        {
          onSuccess: (data) => {
            const session = data as VerificationSessionDto;

            if ('isLegacyAuthUser' in data && data.isLegacyAuthUser) {
              handleLegacyEmailLogin(values.phoneOrEmail, params.from);
              return;
            }

            setVerificationSession(session);
          }
        }
      );
      return;
    }

    createLoginVerificationSessionCommand.mutate(
      {
        phoneNumber: values.phoneOrEmail,
        countryCode: values.callingCode
      },
      {
        onSuccess: (data) => {
          if ('isLegacyAuthUser' in data && data.isLegacyAuthUser) {
            handleLegacyPhoneLogin(values.phoneOrEmail, params.from);
            return;
          }

          setVerificationSession(data as VerificationSessionDto);
        }
      }
    );
  };

  const handlePasswordLogin = (values: { password: string }) => {
    if (!verificationSession?.verificationSessionId) {
      // This should technically never happen, but just in case
      return;
    }

    updateLoginVerificationSessionCommand.mutate(
      {
        verificationSessionId: verificationSession.verificationSessionId,
        password: values.password
      },
      {
        onSuccess: (data) => {
          setVerificationSession(data);
        }
      }
    );
  };

  const handleConfirmLogin = (code: string) => {
    confirmLoginVerificationSessionCommand.mutate(
      {
        smsCode: code,
        verificationSessionId: verificationSession.verificationSessionId
      },
      {
        onSuccess: (data) => {
          handleLogin(data);
        }
      }
    );
  };

  const onReset = () => {
    setVerificationSession(null);

    createLoginVerificationSessionCommand.reset();
    updateLoginVerificationSessionCommand.reset();
  };

  if (
    (verificationSession &&
      !verificationSession.requiresPasswordVerification) ||
    verificationSession?.hasVerifiedPassword
  ) {
    const loginMethod = getLoginMethod(phoneOrEmail);

    return (
      <Wrapper>
        <LoginConfirmation
          onBackClick={onReset}
          isEmailLogin={loginMethod === 'email'}
          verificationSession={
            createLoginVerificationSessionCommand.data as VerificationSessionDto
          }
          onSubmit={handleConfirmLogin}
          isLoading={confirmLoginVerificationSessionCommand.isLoading}
          errorMessage={confirmLoginVerificationSessionCommand.error?.message}
        />
      </Wrapper>
    );
  }

  if (verificationSession?.requiresPasswordVerification) {
    return (
      <Wrapper>
        <FormWrapper>
          <LoginWithPasswordForm
            phoneOrEmail={phoneOrEmail}
            onReset={onReset}
            onSubmit={handlePasswordLogin}
            isLoading={updateLoginVerificationSessionCommand.isLoading}
            errorMessage={updateLoginVerificationSessionCommand.error?.message}
          />
          <SignUpButton />
        </FormWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FormWrapper>
        <LoginForm
          onSubmit={handleInitialLogin}
          isLoading={createLoginVerificationSessionCommand.isLoading}
          errorMessage={createLoginVerificationSessionCommand.error?.message}
        />
        <OtherLoginMethods />
        <SignUpButton />
      </FormWrapper>
    </Wrapper>
  );
};

export default Login;
