import Head from 'next/head';

import useParams from 'legacy/common/hooks/useParams';

import EmailLogin from 'legacy/auth/screens/legacy/EmailLogin.screen';
import PhoneLogin from 'legacy/auth/screens/legacy/PhoneLogin.screen';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAGS } from 'legacy/common/constants/launchdarkly';

import Login from 'legacy/auth/screens/Login.screen';
import Loader from 'legacy/common/components/layout/Loader';

type LoginPageParams = {
  type: 'email' | 'phone';
};

/**
 * NOTE: Do not remove login types as a part of the feature flag removal.
 * These are still used for legacy logins.
 * Only remove these once we completely disable the legacy logins.
 */
const loginType = {
  phone: <PhoneLogin />,
  email: <EmailLogin />
};

const LoginPage = () => {
  const flags = useFlags();
  const params = useParams<LoginPageParams>();

  if (!(FLAGS.ENABLE_NEW_LOGIN_SCREEN in flags)) {
    return <Loader isLoading />;
  }

  return (
    <>
      <Head>
        <title>Bounce</title>
      </Head>

      {flags[FLAGS.ENABLE_NEW_LOGIN_SCREEN] && !params.type ? (
        <Login />
      ) : (
        loginType[params?.type] || loginType.phone
      )}
    </>
  );
};

export default LoginPage;
