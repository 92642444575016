import styles from 'legacy/common/components/form/Form.module.scss';

import { useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';

import { showModal } from 'legacy/actions/modalActions';

import { handleLogin } from 'legacy/auth/helpers';
import {
  usePhoneLoginCommand,
  usePhoneLoginRequestCommand,
  usePhoneRegisterCommand
} from 'legacy/auth/hooks';

import Button, { ButtonType } from 'legacy/common/components/button/Button';

type PhoneLoginConfirmationProps = {
  phoneNumber: string;
  setHasRequestedcode: (hasRequestedCode: boolean) => void;
};

const PhoneLoginConfirmation: React.FC<PhoneLoginConfirmationProps> = ({
  phoneNumber,
  setHasRequestedcode
}) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const {
    mutate: phoneLoginCommand,
    error: phoneLoginError,
    isLoading: isLoadingPhoneLogin,
    reset: resetPhoneLoginCommand
  } = usePhoneLoginCommand();
  const {
    mutate: resendVerificationCommand,
    error: resendError,
    isLoading: isLoadingResend
  } = usePhoneLoginRequestCommand();

  const {
    mutate: phoneRegisterCommand,
    error: phoneRegisterError,
    isLoading: isLoadingPhoneRegister
  } = usePhoneRegisterCommand();

  const onSubmit = (code: string, phone: string) => {
    phoneLoginCommand(
      {
        code,
        phone
      },
      {
        onSuccess: (data) => {
          handleLogin(data);
        },
        onError: (error) => {
          if (error.code === 'registrationRequired') {
            // Resets phone error messages
            resetPhoneLoginCommand();

            dispatch(
              showModal('CONFIRMATION', {
                message: error?.message,
                actionText: 'Yes',
                cancelText: 'Try another method',
                onCancel: () => {
                  setHasRequestedcode(false);
                },
                action: () => {
                  phoneRegisterCommand(
                    {
                      code,
                      phone
                    },
                    {
                      onSuccess: (data) => {
                        handleLogin(data);
                      }
                    }
                  );
                }
              })
            );
          }
        }
      }
    );
  };

  return (
    <>
      <div className={styles.group}>
        <p className={styles.submitError}>
          {phoneLoginError?.message ||
            phoneRegisterError?.message ||
            resendError?.message}
        </p>
      </div>
      <div className={styles.group}>
        <p className={classnames(styles.label, styles.small)}>
          Verification Code
        </p>
        <div className={styles.codeInputContainer}>
          <ReactCodeInput
            fields={4}
            fieldWidth={45}
            fieldHeight={60}
            onComplete={(val) => {
              onSubmit(val, phoneNumber);
              setCode(val);
            }}
            className={styles.codeInput}
            placeholder={['#', '#', '#', '#']}
          />
        </div>
      </div>
      <div className={styles.FormGroup}>
        <Button
          disabled={!code}
          loading={isLoadingPhoneLogin || isLoadingPhoneRegister}
          type={ButtonType.Primary}
          onClick={() => {
            onSubmit(code, phoneNumber);
          }}
        >
          Confirm Code
        </Button>
      </div>
      <div className={styles.group}>
        {isLoadingResend ? (
          'Sending Again...'
        ) : (
          <>
            {"Didn't receive? "}
            <span
              className={styles.actionItem}
              onClick={() => {
                resendVerificationCommand(phoneNumber);
              }}
            >
              Send Again
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default PhoneLoginConfirmation;
