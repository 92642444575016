import styles from 'legacy/common/components/form/Form.module.scss';

import { useEffect, useState } from 'react';
import classnames from 'classnames';
import TelInput from 'react-intl-tel-input';

import { getCountryCode } from 'legacy/common/utils/country';
import { SUPPORTED_COUNTRIES } from 'legacy/common/constants/countries';

import { parsePhoneNumber } from 'legacy/auth/helpers';
import { usePhoneLoginRequestCommand } from 'legacy/auth/hooks';

import Button, { ButtonType } from 'legacy/common/components/button/Button';
import useParams from 'legacy/common/hooks/useParams';

type PhoneFormProps = {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  setHasRequestedcode: (hasRequestedCode: boolean) => void;
};

const PhoneForm: React.FC<PhoneFormProps> = ({
  phoneNumber,
  setPhoneNumber,
  setHasRequestedcode
}) => {
  const { mutate, error, isLoading } = usePhoneLoginRequestCommand();
  const [input, setInput] = useState('');

  const params = useParams<{ phone: string }>();
  useEffect(() => {
    // Prefill phone number if provided in params
    if (params.phone) {
      setInput(params.phone);
    }
  }, [params.phone]);

  const currentCountryCode = getCountryCode();
  const countryCode = SUPPORTED_COUNTRIES.includes(currentCountryCode)
    ? currentCountryCode
    : 'CA';

  const handlePhoneLogin = (number: string) => {
    if (!number) {
      return;
    }

    mutate(parsePhoneNumber(number), {
      onSuccess: () => {
        setHasRequestedcode(true);
      }
    });
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && phoneNumber) {
      handlePhoneLogin(phoneNumber);
    }
  };

  return (
    <>
      <p className={styles.submitError}>{error?.message}</p>
      <div className={styles.group}>
        <p className={classnames(styles.label, styles.small)}>Phone Number</p>
        <TelInput
          format
          value={input}
          autocomplete="tel"
          fieldName="number"
          preferredCountries={[]}
          inputClassName={styles.input}
          onlyCountries={SUPPORTED_COUNTRIES}
          customPlaceholder={() => 'Enter your phone number'}
          defaultCountry={countryCode?.toLowerCase() || 'ca'}
          containerClassName={classnames(
            'intl-tel-input ',
            styles.phoneContainer
          )}
          telInputProps={{ onKeyPress: handleKeyPress }}
          onPhoneNumberChange={(
            valid: boolean,
            value: string,
            _,
            fullNumber: string
          ) => {
            // value = phone number without country code
            if (parsePhoneNumber(value)?.length > 10) {
              return;
            }
            setInput(value);

            // fullNumber = phone number with country code
            if (valid) {
              setPhoneNumber(fullNumber);

              // Auto submit if the phone number is valid
              handlePhoneLogin(fullNumber);
              return;
            }
            setPhoneNumber('');
          }}
        />
        <div className={styles.group}>
          <Button
            loading={isLoading}
            disabled={!phoneNumber}
            type={ButtonType.Primary}
            onClick={() => handlePhoneLogin(phoneNumber)}
          >
            Get Started
          </Button>
        </div>
      </div>
    </>
  );
};

export default PhoneForm;
