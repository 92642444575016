import styles from 'legacy/common/components/form/Form.module.scss';

import { useState } from 'react';

import FormWrapper from 'legacy/auth/components/FormWrapper';
import PhoneForm from 'legacy/auth/components/legacy/PhoneForm';
import SocialLogin from 'legacy/auth/components/legacy/SocialLogin';
import Wrapper from 'legacy/common/components/layout/BackgroundWrapper';
import PhoneLoginConfirmation from 'legacy/auth/components/legacy/PhoneLoginConfirmation';
import { FLAGS } from 'legacy/common/constants/launchdarkly';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PhoneLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hasRequestedCode, setHasRequestedcode] = useState(false);
  const flags = useFlags();

  if (hasRequestedCode) {
    return (
      <Wrapper>
        <FormWrapper
          title="Confirmation"
          subtitle="We sent a confirmation code on your phone number."
        >
          <PhoneLoginConfirmation
            phoneNumber={phoneNumber}
            setHasRequestedcode={setHasRequestedcode}
          />
        </FormWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FormWrapper>
        <PhoneForm
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setHasRequestedcode={setHasRequestedcode}
        />

        {!flags[FLAGS.ENABLE_NEW_LOGIN_SCREEN] && (
          <>
            <div className={styles.separator}>
              <span />
              <div>OR</div>
              <span />
            </div>

            <SocialLogin type="email" />
          </>
        )}
      </FormWrapper>
    </Wrapper>
  );
};

export default PhoneLogin;
