import Link from 'next/link';
import { useFlags } from 'launchdarkly-react-client-sdk';

import styles from './auth.module.scss';
import formStyles from 'legacy/common/components/form/Form.module.scss';

import FormWrapper from 'legacy/auth/components/FormWrapper';
import SocialLogin from 'legacy/auth/components/legacy/SocialLogin';
import Wrapper from 'legacy/common/components/layout/BackgroundWrapper';
import EmailLoginForm from 'legacy/auth/components/legacy/EmailLoginForm';
import { useRouter } from 'next/router';
import { FLAGS } from 'legacy/common/constants/launchdarkly';

const EmailLogin = () => {
  const flags = useFlags();
  const { query } = useRouter();

  return (
    <Wrapper>
      <FormWrapper>
        <EmailLoginForm />
        <div className={styles.forgotPassword}>
          Forgot Password?{' '}
          <Link href="/login/resetPassword" className={styles.link}>
            Reset
          </Link>
        </div>

        {!flags[FLAGS.ENABLE_NEW_LOGIN_SCREEN] && (
          <>
            <div className={formStyles.separator}>
              <span />
              <div>OR</div>
              <span />
            </div>
            <SocialLogin />
          </>
        )}

        <div className={formStyles.separator}>
          <span />
          <div>OR</div>
          <span />
        </div>
        <div className={styles.actionItem}>
          <Link
            href={{
              pathname: '/register',
              query
            }}
            data-test="sign-up"
            className={formStyles.button}
          >
            Sign Up
          </Link>
        </div>
      </FormWrapper>
    </Wrapper>
  );
};

export default EmailLogin;
