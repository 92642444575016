import styles from './Socials.module.scss';
import formStyles from 'legacy/common/components/form/Form.module.scss';

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Dimmer, Loader } from 'semantic-ui-react';

import { handleLogin, initAppleID } from 'legacy/auth/helpers';
import { useAppleLoginCommand } from 'legacy/auth/hooks';

import { isSnapchatWebview } from 'legacy/common/utils/webview';

import { useRouter } from 'next/router';

type SocialLoginProps = {
  type?: 'phone' | 'email';
};

const SocialLogin: React.FC<SocialLoginProps> = ({ type }) => {
  const { query } = useRouter();

  const {
    mutate: appleLoginCommand,
    isLoading: isLoadingAppleLogin,
    error: appleError,
    reset: resetAppleLoginCommand
  } = useAppleLoginCommand({ autoRegister: true });

  // Resets error messages and related states
  const reset = () => {
    resetAppleLoginCommand();
  };

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const { removeEventListeners } = initAppleID({
      setErrorMessage,
      onSuccess: (data) => {
        reset();

        appleLoginCommand(data, {
          onSuccess: (data) => {
            handleLogin(data);
          }
        });
      }
    });

    return removeEventListeners;
  }, []);

  const appleLogin = async () => {
    if (isSnapchatWebview(window.navigator.userAgent)) {
      setErrorMessage(
        'Apple login is unsupported from Snapchat browsers. Please switch to the app or use a different browser to continue.'
      );
      return;
    }

    (window as any).AppleID?.auth?.signIn(); // From https://developer.apple.com/documentation/sign_in_with_apple/authi/3261300-signin
  };

  return (
    <>
      <div className={styles.buttonGroup}>
        {type === 'email' ? (
          <div className={styles.optionButton}>
            <Link
              href={{
                pathname: '/login',
                query: {
                  ...query,
                  type: 'email'
                }
              }}
              className={styles.phone}
              data-test="btn-email-login"
            >
              <img src="/img/icons/envelope.svg" />
            </Link>
          </div>
        ) : (
          <div className={styles.optionButton}>
            <Link
              href={{
                pathname: '/login',
                query: {
                  ...query,
                  type: 'phone'
                }
              }}
              className={styles.phone}
              data-test="img-phone"
            >
              <img src="/img/icons/phone.svg" />
            </Link>
          </div>
        )}

        <div className={styles.optionButton}>
          <div className={styles.apple} onClick={appleLogin}>
            <img src="/img/login/apple.svg" alt="Apple" />
          </div>
        </div>
      </div>

      {isLoadingAppleLogin && (
        <Dimmer active>
          <Loader active />
        </Dimmer>
      )}
      <p className={formStyles.submitError}>
        {appleError?.message || errorMessage}
      </p>
    </>
  );
};

export default SocialLogin;
